import TableContainer from 'Common/TableContainer';
import React, {useEffect, useMemo, useState} from 'react';
import {Card, Col, Dropdown} from 'react-bootstrap';

import ApiUtils from 'api/ApiUtils';
import {toast} from 'react-toastify';
import moment from 'moment';
import {ORDER_STATUS} from 'Common/constants/layout';

const RecentOrders = (): any => {
  const [recentOrderData, setRecentOrderData] = useState([]);
  const fetchRecentOrders = async (): Promise<void> => {
    try {
      const resp: any = await ApiUtils.getRecentOrders();
      const firstFiveOrders = resp.data.data.slice(0, 5);
      setRecentOrderData(firstFiveOrders);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    void fetchRecentOrders();
  }, []);

  const recentOrders = recentOrderData.map((data: any) => ({
    purchaseID: `${data?.id}`,
    customerId: `${data?.customer_id}`,
    customerName: `${data?.customer_address?.customer_first_name} ${data?.customer_address?.customer_last_name}`,
    productName: data?.order_detail[0]?.product_name,
    amount: `₹${data.total_amount}`,
    orderDate: moment(data.created_at).format('DD-MM-YYYY'),
    status:
      data.order_status === ORDER_STATUS.PENDING
        ? 'Pending'
        : data.order_status === ORDER_STATUS.ACCEPTED
          ? 'Accepted'
          : data.order_status === ORDER_STATUS.DELIVERY_PERSON_ASSIGN
            ? 'Delivery Person Assign'
            : data.order_status === ORDER_STATUS.DELIVERED
              ? 'Delivered'
              : '',
  }));

  const columns = useMemo(
    () => [
      {
        Header: 'Order ID',
        accessor: 'purchaseID',
        disableFilters: true,
        filterable: true,
      },
      {
        Header: 'Customer ID',
        accessor: 'customerId',
        disableFilters: true,
        filterable: true,
      },
      {
        Header: 'Product Name',
        accessor: 'productName',
        disableFilters: true,
        filterable: true,
      },

      {
        Header: 'Amount',
        accessor: 'amount',
        disableFilters: true,
        filterable: true,
      },
      {
        Header: 'Order Date',
        accessor: 'orderDate',
        disableFilters: true,
        filterable: true,
      },
      {
        Header: 'Status',
        disableFilters: true,
        filterable: true,
        accessor: (cellProps: any) => {
          switch (cellProps.status) {
            case 'Delivered':
              return (
                <span className="badge bg-success-subtle text-success">
                  {' '}
                  {cellProps.status}
                </span>
              );
            case 'Accepted':
              return (
                <span className="badge bg-primary-subtle text-primary">
                  {' '}
                  {cellProps.status}
                </span>
              );
            case 'Pending':
              return (
                <span className="badge bg-warning-subtle text-warning">
                  {' '}
                  {cellProps.status}
                </span>
              );
            case 'Delivery Person Assign':
              return (
                <span className="badge bg-info-subtle text-info">
                  {' '}
                  {cellProps.status}
                </span>
              );
            default:
              return (
                <span className="badge bg-success-subtle text-success">
                  {' '}
                  {cellProps.status}
                </span>
              );
          }
        },
      },
      {
        Header: 'Action',
        disableFilters: true,
        filterable: true,
        accessor: (cellProps: any): React.JSX.Element => {
          return (
            <Dropdown className="text-center">
              <Dropdown.Toggle className="btn btn-soft-secondary btn-sm btn-icon dropdown arrow-none">
                <i className="mdi mdi-dots-horizontal" />
              </Dropdown.Toggle>
              <Dropdown.Menu as="ul" className="dropdown-menu-end">
                <li>
                  <Dropdown.Item href={`/order-detail/${cellProps.purchaseID}`}>
                    <i className="ri-eye-fill align-bottom me-2 text-muted" />{' '}
                    View Order
                  </Dropdown.Item>
                </li>
              </Dropdown.Menu>
            </Dropdown>
          );
        },
      },
    ],
    [],
  );

  return (
    <Col xxl={12}>
      <Card>
        <Card.Header className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Recent Orders</h4>
        </Card.Header>

        <TableContainer
          columns={columns}
          data={recentOrders}
          customPageSize={5}
          pageValue={1}
          className="custom-header-css table-card"
          tableClass="table-centered align-middle table-nowrap mb-0"
          theadClass="text-muted table-light"
          SearchPlaceholder="Search Order..."
          isNotPagination={true}
        />
      </Card>
    </Col>
  );
};

export default RecentOrders;
