import React from 'react';
import {Container} from 'react-bootstrap';
import Widgets from './Widgets';
import Revenue from './Revenue';
import RecentOrders from './RecentOrders';
import {DOCUMENT_TITLE} from 'Common/constants/layout';

const Dashboard = (): React.JSX.Element => {
  document.title = DOCUMENT_TITLE.DASHBOARD_TITLE;
  return (
    <div className="page-content">
      <Container fluid>
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-sm-0">Dashboard</h4>
        </div>
        <Widgets />
        <Revenue />
        <RecentOrders />
      </Container>
    </div>
  );
};

export default Dashboard;
